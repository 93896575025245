import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import { FaNewspaper } from "@react-icons/all-files/fa/FaNewspaper";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import SEO from "../components/seo";

export const pageQuery = graphql`
query($slug: String!) {
  markdownRemark(frontmatter: { slug: { eq: $slug } }) {
    html
    tableOfContents
    excerpt(pruneLength: 200)
    frontmatter {
      tags
      date(formatString: "MMM DD, YYYY")
      published
      title
      slug
    }
  }
}
`;

const PostPageTemplate = ({ data }) => {
    const post = data.markdownRemark
    return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />

      <Link to='/posts'>
        <div className='flex flex-row items-center'>
          <FaArrowLeft className='p-2' size={50} />
          <h3 className='font-semibold'>{post.frontmatter.slug}</h3>
        </div>
      </Link>

      <div className="p-4 m-4 shadow-md bg-white">
        <div className="flex w-full items-center justify-between border-b pb-3">
          <div className="flex items-center space-x-3">
            <div className="text-lg font-bold text-black">
              <div className='flex flex-row items-center'>
                <FaNewspaper className='p-2' size={50} />
                <h2 className='font-semibold text-3xl'>{post.frontmatter.title}</h2>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-8">
            <div className="rounded-2xl border px-3 py-1 text-xs font-semibold">{post.frontmatter.tags}</div>
            <div className="text-xs text-black">{post.frontmatter.date}</div>
          </div>
        </div>
        <div className="mt-4 mb-6">
          <div className='markdown' dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
    </Layout>
    )
}

export default PostPageTemplate