import * as React from "react"
import { Link } from 'gatsby'
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaRss } from "@react-icons/all-files/fa/FaRss";


const Footer = () => (
	<footer className="bg-red border-black border-t-2">
		<div className="bg-darkred text-white">
			<div className="container mx-auto">

				<p className="pt-4 pb-2">Find Me Elsewhere</p>

				<div className="flex pb-4">
					<Link className="pr-2 hover:text-grey" to="https://www.linkedin.com/in/connor-temple/" target="_blank">
						<FaLinkedin size={25}></FaLinkedin>
					</Link>

					<Link className="pr-2 hover:text-grey" to="https://github.com/whoisronnoc" target="_blank">
						<FaGithub size={25}></FaGithub>
					</Link>

					<Link className="pr-2 hover:text-grey" to="https://twitter.com/technanigans" target="_blank">
						<FaTwitter size={25}></FaTwitter>
					</Link>

					<Link className="pr-2 hover:text-grey" to="https://instagram.com/technanigans" target="_blank">
						<FaInstagram size={25}></FaInstagram>
					</Link>

					<Link className="pr-2 hover:text-grey" to="https://technanigans.com">
						<FaRss size={25}></FaRss>
					</Link>
				</div>
			</div>
		</div>
		<div className="container mx-auto">
			<div className="py-4 text-white">
				© {new Date().getFullYear()} <a href="https://connortemple.com" className="hover:underline">Connor Temple</a>
			</div>
		</div>
	</footer>
)

export default Footer
