import * as React from "react"
import { Link } from 'gatsby'
import { FaBars} from '@react-icons/all-files/fa/FaBars'

const links = [
  { name: 'Apps', href: 'https://ronnoc.app', current: false },
  { name: 'Projects', href: 'https://connortemple.com', current: false },
  { name: 'Blog', href: '/posts', current: false },
]

const NavBar = () => (
<div className="bg-red flex justify-between items-center py-4 border-b-black border-b-2">
	<div className="flex-shrink-0 ml-10 cursor-pointer">
		<Link to="/">
			<span className="ml-1 text-3xl text-white hover:underline font-semibold">Technanigans</span>
		</Link>
	</div>
	<FaBars className="visible md:invisible mr-10 md:mr-0 text-white cursor-pointer" size={25}></FaBars>
	<ul className="hidden md:flex overflow-x-hidden mr-10 font-semibold">
		{links.map(link => (
			<li key={link.name} className="mr-6 p-1">
				<Link key={link.name} to={link.href} className="text-white hover:underline">
					{link.name}
				</Link>
			</li>
		))}
	</ul>
</div>
)

export default NavBar