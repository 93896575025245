import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
    <div className="bg-grey min-h-screen">
      <Header/>
      <div className="container mx-auto">
        <main>{children}</main>
      </div>
      <Footer/>
    </div>
    </>
  )
}

export default Layout
